// Return the time in format DD.MM.YY HH:MM
export function formatTimestamp(timestamp: number) {
  const date = new Date(timestamp);
  return `${String(date.getDate()).padStart(2, '0')}.${String(
    date.getMonth() + 1,
  ).padStart(2, '0')}.${String(date.getFullYear()).slice(2)} ${String(
    date.getHours(),
  ).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`;
}

// Returns "25 Jul Mon"
export function formatWeekDayUTC(timestamp: number) {
  const date = new Date(timestamp);
  return `${String(date.getUTCDate()).padStart(2, '0')} ${date
    .toLocaleString('default', {month: 'short'})
    .slice(0, 3)} ${date.toLocaleString('default', {weekday: 'short'})}`;
}

// Return the time in the format of HH:MM or MM:SS
export function formatRemainingSeconds(secondsRemaining: number) {
  const hours = Math.floor(secondsRemaining / 3600);
  const minutes = Math.floor((secondsRemaining % 3600) / 60);
  const seconds = Math.floor(secondsRemaining % 60);

  return `${hours ? hours + ':' : ''}${String(minutes).padStart(
    2,
    '0',
  )}:${String(seconds).padStart(2, '0')}`;
}

// Return formatted string of how long age the date was
// few minutes ago, 2h ago, 3d ago, 4w ago, 5m ago, 6y ago
export function formatTimeAgo(date: Date) {
  const seconds = Math.floor((new Date().getTime() - date.getTime()) / 1000);

  let interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + 'y ago';
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + 'm ago';
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + 'd ago';
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + 'h ago';
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + 'm ago';
  }
  return Math.floor(seconds) + 's ago';
}

// Converts time in milliseconds to rounded hours
export function msToHours(ms: number) {
  return Number((ms / 1000 / 60 / 60).toFixed(2));
}

export type ServerTimestamp = number & {readonly __brand: 'ServerTimestamp'};
export type ClientTimestamp = number & {readonly __brand: 'ClientTimestamp'};

export function toClientTimestamp(t: ServerTimestamp): ClientTimestamp {
  return (t * 1000) as ClientTimestamp;
}

export function toServerTimestamp(
  t: ClientTimestamp | number,
): ServerTimestamp {
  return (t * 1000) as ServerTimestamp;
}

// Returns remaining time in days and hours
// Or hours and minutes if remaining time is less than 24 hours
// Example: 13 days : 24 hours
// Example: 24 hours : 59 minutes
export function formatCountdownWords(date: Date, isShort = false) {
  const now = new Date();
  const diff = date.getTime() - now.getTime();
  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
  const minutes = Math.floor((diff / 1000 / 60) % 60);

  const HOURS_LABEL = isShort ? 'h' : ' hours';
  const MINUTES_LABEL = isShort ? 'm' : ' minutes';
  const DAYS_LABEL = isShort ? 'd' : ' days';

  // check if negative
  if (diff < 0) {
    return `0${HOURS_LABEL} : 0${MINUTES_LABEL}`;
  }

  if (days > 0) {
    return `${days.toString().padStart(2, '0')}${DAYS_LABEL} : ${hours
      .toString()
      .padStart(2, '0')}${HOURS_LABEL}`;
  }

  return `${hours.toString().padStart(2, '0')}${HOURS_LABEL} : ${minutes
    .toString()
    .padStart(2, '0')}${MINUTES_LABEL}`;
}
