import {useUtils} from '@telegram-apps/sdk-react';
import {IReward, UserRewardStatus} from '~/api/rewards';
import {useAppTheme} from '~/components/kit';
import {AspectRatio} from '~/components/kit/aspect-ratio';
import {BwButton} from '~/components/kit/bw-button';
import {DoneRibbon} from '~/components/kit/done-ribbon';
import {Icon} from '~/components/kit/icons';
import {ArrowRightIcon} from '~/components/kit/icons/arrow-right-icon';
import {ImgTransition} from '~/components/kit/img-transition';
import {Typography} from '~/components/kit/typography';
import {useDockModel} from '~/entities/dock';
import {useRewards} from '~/entities/rewards';
import {Reward} from '~/pages/rewards-page/ui/reward';
import {rem} from '~/shared/utils/style-helpers';

export function PartnerTask({task}: {task: IReward}) {
  const theme = useAppTheme();
  const dock = useDockModel();

  const utils = useUtils();

  const rewardsModel = useRewards();
  const userReward = rewardsModel.model.userReward(task.name);

  const rewardAmount =
    task.amount + task.multiplier * dock.dock.combined_lunar_loot_speed;

  const isDone = !!(
    userReward && userReward.status === UserRewardStatus.COMPLETED
  );
  const isPending =
    !!userReward && userReward.status === UserRewardStatus.IN_PROGRESS;

  return (
    <div
      css={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        borderRadius: rem(18),
        background: theme.colors.surfaceAlternative,
        color: theme.colors.onSurfaceAlternative,
        padding: rem(10),
        boxShadow: isDone ? '2px 3px 0px 0px #5DCB90' : 'none',
        border: isDone ? '2px #5DCB90' : 'none',
      }}
      onClick={async () => {
        if (isDone || isPending) {
          return;
        }

        if (!rewardsModel.claimRewardsMutation.isPending) {
          await rewardsModel.claimRewardsMutation.mutateAsync({
            reward_id: task.id,
          });
        }

        utils.openLink(task.link, {});
      }}
    >
      <DoneRibbon
        isDone={isDone}
        isSoon={false}
        css={{
          zIndex: 2,
        }}
      />
      <AspectRatio
        ratio={1}
        css={{
          width: rem(56),
          background: theme.colors.surface,
          borderRadius: rem(10),
          marginRight: rem(10),
          overflow: 'hidden',
        }}
      >
        <ImgTransition
          src={task.image || ''}
          css={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        ></ImgTransition>
      </AspectRatio>

      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          marginRight: 'auto',
        }}
      >
        <Typography.h6
          css={{
            fontWeight: 800,
          }}
        >
          {task.description || ''}
        </Typography.h6>

        <div
          css={{
            marginTop: rem(10),
          }}
        >
          <Reward isAlternativeColor={false} value={rewardAmount}></Reward>
          {!!userReward && !userReward.redeemed ? (
            <Typography.body
              css={{
                color: theme.colors.onSurfaceAlternativeSecondary,
              }}
            >
              Wait, we're checking...
            </Typography.body>
          ) : null}
        </div>
      </div>
      <BwButton
        css={{
          flexShrink: 0,
          marginLeft: rem(10),
          height: 'unset',
          alignSelf: 'stretch',
          visibility: isDone || isPending ? 'hidden' : 'visible',
        }}
      >
        <Icon size={20}>
          <ArrowRightIcon />
        </Icon>
      </BwButton>
    </div>
  );
}
