import {AspectRatio} from '~/components/kit/aspect-ratio';
import {ImgTransition} from '~/components/kit/img-transition';
import llaBannerPng from './assets/lla-banner.png';
import {Link} from '~/components/Link/Link';
import {RoutePath} from '~/app/navigation/routes';
import {useSignals} from '@preact/signals-react/runtime';
import {isEarnPageReleasedForUser} from '~/entities/earn';
import {useAppTheme} from '~/components/kit';
import {Box} from '~/components/kit/box';
import {Typography} from '~/components/kit/typography';
import {rem} from '~/shared/utils/style-helpers';
import {FallingImages} from './animation';
import moonSvg from '~/components/kit/moon-value/moon.svg';

export function AirdropPendingAd() {
  const theme = useAppTheme();

  return (
    <Box
      backgroundColor="#F63C70"
      radius={30}
      css={{
        padding: rem(16),
        color: theme.colors.onSurfaceAlternative,
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        minHeight: rem(120),
      }}
      bgImage={<FallingImages imageSrc={moonSvg} visibleImages={10} />}
    >
      <Typography.h1
        css={{
          lineHeight: 1,
          fontSize: rem(32),
          marginBottom: rem(8),
        }}
      >
        Cooking your <br /> experience...
      </Typography.h1>

      <Typography.h3
        css={{
          lineHeight: 1,
        }}
      >
        It might take up to 24hrs
      </Typography.h3>
    </Box>
  );
}

export function LunarLootAbsorptionAd() {
  useSignals();

  if (!isEarnPageReleasedForUser.value) {
    return <AirdropPendingAd />;
  }

  return (
    <Link to={RoutePath.BlackHole}>
      <AspectRatio ratio={150 / 335}>
        <ImgTransition src={llaBannerPng} />
      </AspectRatio>
    </Link>
  );
}
