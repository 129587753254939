import {useAppTheme} from '~/components/kit';
import {UserAvatar} from '~/components/smarts/user-avatar';
import {fullName} from '~/entities/referral-link/utilts';
import {useCurrentUser} from '~/entities/user';
import {WalletOpenButton} from '~/features/connect-wallet';
import {FriendsNavigationLink} from '~/features/friends/ui/friends-navigation-link';
import {rem} from '~/shared/utils/style-helpers';

export function Header({...rest}: React.HTMLProps<HTMLDivElement>) {
  const user = useCurrentUser();
  const theme = useAppTheme();

  return (
    <header
      css={{
        display: 'grid',
        gridTemplateColumns: '1fr auto 1fr',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
      {...rest}
    >
      <div
        css={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <UserAvatar size="medium" />
        <div
          css={{
            fontSize: theme.fontSize.s6,
            fontWeight: 600,
            color: theme.colors.onSurface,
            marginLeft: rem(8),
          }}
        >
          {fullName(user)}
        </div>
      </div>
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
        }}
      ></div>
      <div
        css={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <FriendsNavigationLink />
        <WalletOpenButton />
      </div>
    </header>
  );
}
