import {useEffect, useState} from 'react';
import {formatRemainingSeconds} from '../format/format-time';

export function useCountdown(timeLeftInSeconds: number, onEnd?: () => void) {
  const [seconds, setSeconds] = useState(timeLeftInSeconds);

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds(Math.max(seconds - 1, 0));
    }, 1000);

    if (seconds === 0 && onEnd) {
      onEnd();
    }

    return () => clearInterval(interval);
  }, [seconds]);

  useEffect(() => {
    setSeconds(timeLeftInSeconds);
  }, [timeLeftInSeconds]);

  return seconds;
}

export function Countdown({
  timeLeft,
  onEnd,
}: {
  timeLeft: number;
  onEnd: () => void;
}) {
  const seconds = useCountdown(timeLeft, onEnd);

  return <span>{formatRemainingSeconds(seconds)}</span>;
}

export function secondsUntilDate(date: Date) {
  return Math.max(
    0,
    Math.round((date.getTime() - new Date().getTime()) / 1000),
  );
}
