import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {Card, MoonValue, useAppTheme} from '~/components/kit';
import {MicroButton} from '~/components/kit/button/button';
import {Star, StarField} from '~/components/kit/star';
import {useDockModel} from '~/entities/dock';
import {lunar} from '~/entities/lunar-loot';
import {useCurrentUser, useUserQuery} from '~/entities/user';
import {useClaimYield} from '~/features/yield';
import {YieldCalculator} from '~/features/yield/math';
import {opacity} from '~/shared/utils/colors';
import {formatNumber} from '~/shared/utils/format/format-numbers';
import {
  formatRemainingSeconds,
  msToHours,
  toClientTimestamp,
} from '~/shared/utils/format/format-time';
import {rem} from '~/shared/utils/style-helpers';

function useAsyncAction(action: () => Promise<void>) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState<Error | null>(null);

  const execute = async () => {
    setIsLoading(true);
    try {
      await action();
    } catch (e) {
      setError(e as Error);
    } finally {
      setIsLoading(false);
    }
  };

  return {isLoading, error, execute};
}

export function Storage() {
  const dockModel = useDockModel();
  const dockQuery = dockModel.dockQuery;

  const dock = dockModel.dock;

  const claimYield = useClaimYield();
  const userQuery = useUserQuery();

  const theme = useAppTheme();
  const {t} = useTranslation();

  const calculator = new YieldCalculator({
    yield_percentage: dock.yield_percentage,
    yield_period: toClientTimestamp(dock.yield_period),
    last_yield_time: toClientTimestamp(dock.last_yield_time),
  });

  let yieldAmount = formatNumber(lunar(calculator.yieldSize(dock.balance)));
  yieldAmount = yieldAmount.length > 8 ? '' : yieldAmount;

  const {
    isLoading,
    error,
    execute: doClaimYield,
  } = useAsyncAction(async () => {
    await claimYield.mutateAsync();
    await dockQuery.refetch();
    await userQuery.refetch();
  });

  return (
    <StarField
      stars={
        <React.Fragment>
          <Star
            starSize={1.2}
            x={0.5}
            y={0.17}
            rayColor="rgba(0, 0, 0, 1)"
            coreColor="rgba(255, 184, 239, 1)"
            rotation={40}
          ></Star>
        </React.Fragment>
      }
    >
      <Card
        rounding="small"
        isAlternative={true}
        css={{
          paddingLeft: rem(20),
          paddingRight: rem(16),
          paddingTop: rem(14),
          paddingBottom: rem(14),
          height: rem(105),
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div>
          <h6
            css={{
              fontSize: theme.fontSize.s6,
              fontWeight: 500,
              color: opacity(theme.colors.onSurfaceAlternative, 0.8),
              marginBottom: rem(10),
            }}
          >
            {t('mining.balanceTitle')}:
          </h6>
          <MoonValue
            value={formatNumber(Number(lunar(dock.balance)))}
            css={{
              fontSize: rem(32),
            }}
          />
        </div>
        <div
          css={{
            textAlign: 'right',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div
            // Fix i18n trimming whitespaces
            css={{
              whiteSpace: 'pre',
            }}
          >
            {/* TODO: set yield speed */}
            <Trans
              t={t}
              i18nKey={'mining.yield'}
              components={{
                bold: (
                  <span
                    css={{
                      fontWeight: 700,
                    }}
                  ></span>
                ),
              }}
              values={{
                yield: formatNumber(Number(dock.yield_percentage) * 100),
              }}
            ></Trans>
          </div>
          <div
            css={{
              marginTop: calculator.canClaim ? rem(4) : rem(12),
            }}
          >
            <div
              css={{
                fontSize: theme.fontSize.s7,
                color: opacity(theme.colors.onSurfaceAlternative, 0.5),
                marginBottom: rem(4),
              }}
            >
              {t('mining.yieldPeriod', {
                periodInHours: msToHours(toClientTimestamp(dock.yield_period)),
              })}
              {/* {!calculator.canClaim && (
                <div>
                  Claim in:{' '}
                  <Countdown
                    timeLeft={calculator.canClaimIn.getTime() / 1000}
                    onEnd={forceUpdate}
                  ></Countdown>
                </div>
              )} */}
            </div>
            <div
              css={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              {calculator.canClaim ? (
                <MicroButton
                  color="red"
                  isLoading={isLoading}
                  isDisabled={isLoading}
                  onClick={doClaimYield}
                >
                  {t('mining.claimYield')}
                  {yieldAmount && (
                    <MoonValue
                      css={{
                        marginLeft: rem(4),
                      }}
                      value={yieldAmount}
                    />
                  )}
                </MicroButton>
              ) : (
                <div
                  css={{
                    fontWeight: 700,
                    fontSize: theme.fontSize.s6,
                  }}
                >
                  <MoonValue value={yieldAmount || '0.000001'} />
                </div>
              )}
            </div>
          </div>
        </div>
      </Card>
    </StarField>
  );
}
