import '../interfaces';

import {MoonValue, useAppTheme} from '~/components/kit';
import {Bonus} from '~/components/kit/bonus';
import {Box} from '~/components/kit/box';
import {Button} from '~/components/kit/button/button';
import {Typography} from '~/components/kit/typography';
import {lunar} from '~/entities/lunar-loot';
import {rem} from '~/shared/utils/style-helpers';
import adsGramCardBg from './assets/ads-gram-bg.png';
import {ImgTransition} from '~/components/kit/img-transition';
import {EnvironmentVariables} from '~/app/env';
import {useAdsGramModel} from '../model';
import {formatRemainingSeconds} from '~/shared/utils/format/format-time';
import {useCountdown} from '~/shared/utils/hooks';
import {Fragment} from 'react';
import {formatNumber} from '~/shared/utils/format/format-numbers';
import {toast, ToastContent} from '~/features/toasts';

const AdController =
  EnvironmentVariables.ADS_GRAM_ID &&
  window.Adsgram?.init({
    blockId: EnvironmentVariables.ADS_GRAM_ID,
    debug: EnvironmentVariables.isDevelopment,
    debugBannerType: 'FullscreenMedia',
  });

export function AdsGramCard(props: React.HTMLProps<HTMLDivElement>) {
  const adsModel = useAdsGramModel();

  const remainingSeconds = Math.max(
    0,
    adsModel.adsInfo.data.next_try_time -
      Math.round(new Date().getTime() / 1000),
  );

  const countdown = useCountdown(remainingSeconds, () => {
    adsModel.adsInfo.query.refetch();
  });

  const theme = useAppTheme();

  const reward = adsModel.adsInfo.data.reward_amount;

  return (
    <Box
      backgroundColor="#F61252"
      radius={30}
      css={{
        padding: rem(20),
      }}
      bgImage={
        <ImgTransition
          src={adsGramCardBg}
          css={{
            width: '100%',
          }}
        ></ImgTransition>
      }
      {...props}
    >
      <div
        css={{
          color: theme.colors.onSurfaceAlternative,
          marginBottom: rem(16),
        }}
      >
        <Typography.h3
          css={{
            marginBottom: rem(8),
          }}
        >
          Lunar loot for ads!
        </Typography.h3>
        <Typography.body>
          <b>
            {adsModel.adsInfo.data.ad_logs_count}/
            {adsModel.adsInfo.data.ad_logs_count_limit}
          </b>{' '}
          of the ads are available today
        </Typography.body>
      </div>
      <Button
        color="yellow"
        css={{
          width: '100%',
        }}
        isDisabled={adsModel.isOverLimit}
        onClick={() => {
          if (AdController) {
            AdController?.show()
              .then(() => {
                return adsModel.watchAd.mutateAsync();
              })
              .then(() => {
                toast.notify(
                  <ToastContent
                    title="Reward Claimed"
                    text={
                      <Typography.body>
                        <Bonus color="alternative" variant="medium">
                          <MoonValue
                            value={
                              '+' +
                              formatNumber(
                                lunar(reward, {
                                  precision: 3,
                                }),
                              )
                            }
                          ></MoonValue>
                        </Bonus>{' '}
                        added to your balance
                      </Typography.body>
                    }
                  ></ToastContent>,
                );
              })
              .catch(() => {});
          }
        }}
      >
        {!adsModel.isOverLimit && (
          <Fragment>
            Watch an ad & get{' '}
            <Bonus
              color="alternative"
              variant="medium"
              css={{
                marginLeft: rem(4),
              }}
            >
              <MoonValue
                value={
                  '+' +
                  formatNumber(
                    lunar(reward, {
                      precision: 3,
                    }),
                  )
                }
              />
            </Bonus>
          </Fragment>
        )}
        {adsModel.isOverLimit && (
          <Fragment>
            Next try in&nbsp;
            <span
              css={{
                color: theme.colors.onSurface,
              }}
            >
              {formatRemainingSeconds(countdown)}
            </span>
          </Fragment>
        )}
      </Button>
    </Box>
  );
}
