import {IAuthorizationResponse} from '~/api';
import {apiClient} from '~/api/shared/api-client';
import {ServerTimestamp} from '~/shared/utils/format/format-time';

export interface IPlanetOrder {
  created_at: ServerTimestamp;
  planet_order: number[];
  id: number;
}

export interface IPlanetGuess {
  user_id: number;
  planet_order_guess: number[];
  planets_decoded_count: number;
  reward_amount: number;
  planet_order_id: number;
  id: number;
  created_at: ServerTimestamp;
}

function getPlanetOrder(token: IAuthorizationResponse) {
  return apiClient.get<IPlanetOrder>('/planets/current-planet-order/', {
    headers: {
      Authorization: `Bearer ${token.access_token}`,
    },
  });
}

function guessPlanets(
  {
    planetOrder,
  }: {
    planetOrder: number[];
  },
  token: IAuthorizationResponse,
) {
  return apiClient.post<IPlanetGuess>(
    '/planets/planet-logs/',
    {
      planet_order_guess: planetOrder,
    },
    {
      headers: {
        Authorization: `Bearer ${token.access_token}`,
      },
    },
  );
}

function getPlanetGuesses(
  {limit}: {limit?: number},
  token: IAuthorizationResponse,
) {
  return apiClient.get<IPlanetGuess[]>('/planets/planet-logs/', {
    params: {
      limit,
    },
    headers: {
      Authorization: `Bearer ${token.access_token}`,
    },
  });
}

function getLastGuess(token: IAuthorizationResponse) {
  return apiClient.get<IPlanetGuess>('/planets/planet-logs/last', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export const TrailblazerApi = {
  getPlanetOrder,
  guessPlanets,
  getPlanetGuesses,
  getLastGuess,
};
