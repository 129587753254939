import React from 'react';
import {MoonValue} from '~/components/kit';
import {Bonus} from '~/components/kit/bonus/bonus';
import {lunar} from '~/entities/lunar-loot';
import {formatNumber} from '~/shared/utils/format/format-numbers';

interface IRewardProps {
  isAlternativeColor: boolean;
  value: number;
}

export function Reward({
  value,
  isAlternativeColor,
  ...rest
}: IRewardProps & Omit<React.HTMLProps<HTMLDivElement>, 'color'>) {
  return (
    <Bonus color={isAlternativeColor ? 'alternative' : 'primary'} {...rest}>
      <span>+</span>
      <MoonValue value={formatNumber(lunar(value))} />
    </Bonus>
  );
}
