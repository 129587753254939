import {CheckIcon} from '~/components/kit/icons/check';
import {DailyCheckedPath} from './assets/daily-checked-path';
import {Icon} from '~/components/kit/icons';
import {rem} from '~/shared/utils/style-helpers';
import {Bonus} from '~/components/kit/bonus';
import {Reward} from '~/pages/rewards-page/ui/reward';
import {Chip} from '~/components/kit/chip';
import {MoonValue, useAppTheme} from '~/components/kit';
import {shortNumber} from '~/shared/utils/format/format-numbers';
import {lunar, lunarToFloat} from '~/entities/lunar-loot';

const DailyMark = ({
  reward,
  isChecked,
  i,
  ...rest
}: {
  reward: number;
  isChecked: boolean;
  i: number;
}) => {
  const theme = useAppTheme();

  return (
    <div
      css={{
        position: 'relative',
        display: 'flex',
        flexDirection: i % 2 === 0 ? 'column' : 'column-reverse',
        alignSelf: i % 2 === 0 ? 'flex-start' : 'flex-end',
      }}
    >
      <Icon size={24}>
        {isChecked ? (
          <CheckIcon />
        ) : (
          <div
            css={{
              backgroundColor: '#BB68D7',
              width: '100%',
              height: '100%',
              borderRadius: '50%',
            }}
          ></div>
        )}
      </Icon>

      <Chip
        css={{
          paddingLeft: rem(2),
          paddingRight: rem(2),
          minWidth: rem(55),
          position: 'absolute',
          transform:
            i % 2 === 0
              ? 'translateY(-120%) translateX(-50%)'
              : 'translateY(120%) translateX(-50%)',
          left: '50%',

          background: isChecked ? '#000000' : '#A242C4',
          color: theme.colors.onSurfaceAlternative,
          border: '1px solid #000000',
        }}
      >
        <MoonValue
          value={shortNumber(
            Number(
              lunar(reward, {
                precision: 3,
              }),
            ),
          )}
        />
      </Chip>
    </div>
  );
};

export function DailyMissionMarks({
  dailyRewardsArray,
  checkedMarks,
  ...rest
}: {
  dailyRewardsArray: number[];
  checkedMarks: number;
} & React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div {...rest}>
      <div
        css={{
          position: 'relative',
          height: rem(40),
        }}
      >
        <DailyCheckedPath
          css={{
            position: 'absolute',
            color: '#BB68D7',
            width: '100%',
            top: '50%',
            transform: 'translateY(-50%)',
          }}
        />
        <DailyCheckedPath
          css={{
            position: 'absolute',
            color: '#000000',
            width: '100%',
            top: '50%',
            transform: 'translateY(-50%)',
          }}
        />
        <div
          css={{
            position: 'relative',
            display: 'flex',
            justifyContent: 'space-around',
            marginLeft: '5%',
            marginRight: '5%',
            height: rem(40),
          }}
        >
          {dailyRewardsArray.map((reward, i) => (
            <DailyMark
              key={i}
              reward={reward}
              isChecked={i < checkedMarks}
              i={i}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
