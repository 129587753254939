import {rem} from '~/shared/utils/style-helpers';
import {DockUpgradeIcon} from './dock-upgrade-icon';
import {RocketUpgradeIcon} from './rocket-upgrade-icon';
import {StakingUpgradeIcon} from './staking-upgrade-icon';
import {MoonValue, useAppTheme} from '~/components/kit';
import {titleCollapsed} from '~/components/kit/typography';
import {useTranslation} from 'react-i18next';
import {
  formatDockSize,
  formatDockSizeMoons,
  hourlySpeed,
  UpgradeType,
} from '~/entities/upgrades';
import React from 'react';
import {UpgradeButton} from '~/components/kit/button/button';
import {useDockModel} from '~/entities/dock';
import {lunar} from '~/entities/lunar-loot';
import {formatNumber} from '~/shared/utils/format/format-numbers';
import {useUpgradeModel} from '~/features/upgrades/upgrades';

interface IUpgradeProps {
  type: UpgradeType;
  lvl: number;
  onClickUpgrade?: (type: UpgradeType) => void;
}

export function Upgrade({type, lvl, onClickUpgrade}: IUpgradeProps) {
  const dockModel = useDockModel();
  const upgradeModel = useUpgradeModel(type);
  const theme = useAppTheme();
  const {t} = useTranslation();

  const icon =
    type === 'rocket' ? (
      <RocketUpgradeIcon />
    ) : type === 'dock' ? (
      <DockUpgradeIcon />
    ) : (
      <StakingUpgradeIcon />
    );

  const color =
    type === 'rocket' ? 'purple' : type === 'dock' ? 'orange' : 'blue';
  const colors = theme.colors.accents[color];

  const upgradeTitle =
    type === 'rocket'
      ? t('mining.lunarLootSpeed')
      : type === 'dock'
      ? t('mining.dockSize')
      : t('mining.yieldPercentage');

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: rem(12),
      }}
    >
      <div
        css={[
          {
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: rem(40),
            height: rem(40),
            borderRadius: rem(20),
            border: `${rem(2)} solid ${colors.border}`,
            boxShadow: `1px 1px 0 0 ${colors.border}`,
            background: colors.background,
            flexShrink: 0,
          },
        ]}
      >
        {icon}
        <div
          css={[
            titleCollapsed,
            {
              display: 'flex',
              whiteSpace: 'nowrap',
              position: 'absolute',
              background: colors.border,
              padding: `${rem(3)} ${rem(7)}`,
              top: `calc(100% - ${rem(6)})`,
              fontSize: theme.fontSize.s7,
              minWidth: rem(35),
              borderRadius: 99999,
              textAlign: 'center',
              left: '50%',
              transform: 'translateX(-50%)',
            },
          ]}
        >
          {lvl} lvl
        </div>
      </div>
      <div css={{}}>
        <div
          css={{
            fontSize: theme.fontSize.s7,
            color: theme.colors.onSurfaceSecondary,
            marginBottom: rem(2),
            fontWeight: 500,
          }}
        >
          {upgradeTitle}:
        </div>

        <div
          css={{
            fontSize: theme.fontSize.s3,
            fontWeight: 500,
            textAlign: 'left',
            whiteSpace: 'nowrap',
          }}
        >
          {type === 'rocket' ? (
            <>
              <MoonValue
                value={hourlySpeed(upgradeModel.upgrade.currentValue)}
                moonSize="0.75em"
                css={{
                  fontWeight: 800,
                }}
              />
              <span
                css={{
                  fontSize: theme.fontSize.s7,
                }}
              >
                /h
              </span>
            </>
          ) : type === 'dock' ? (
            <React.Fragment>
              <span
                css={{
                  fontWeight: 800,
                }}
              >
                <MoonValue
                  value={formatDockSizeMoons(
                    dockModel.dockCalculator.dockMaxBalance,
                  )}
                ></MoonValue>
              </span>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <span
                css={{
                  fontWeight: 800,
                }}
              >
                {formatNumber(Number(dockModel.dock.yield_percentage) * 100)}%
              </span>
              <span
                css={{
                  fontSize: theme.fontSize.s7,
                }}
              >
                &nbsp;per&nbsp;day
              </span>
            </React.Fragment>
          )}
        </div>
      </div>

      <UpgradeButton
        color={color}
        css={{
          minWidth: rem(90),
          marginLeft: 'auto',
        }}
        onClick={() => {
          if (!onClickUpgrade) return;

          onClickUpgrade(type);
        }}
      >
        {t('mining.upgrade')}
      </UpgradeButton>
    </div>
  );
}
