const ultraBigNumbersFormatter = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 0,
  useGrouping: false,
});

const bigNumbersFormatter = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 2,
  useGrouping: false,
});

const smallNumberFormatter = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 5,
  maximumSignificantDigits: 3,
  useGrouping: false,
});

// formats with comma separators in thousands
const commaSeparatedFormatter = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 2,
  useGrouping: true,
});

export function formatNumber(value: number | string) {
  const number = Number(value);
  if (number > 100) {
    return ultraBigNumbersFormatter.format(number);
  } else if (number > 1) {
    return bigNumbersFormatter.format(number);
  }

  return smallNumberFormatter.format(number);
}

export function formatWithCommas(value: number | string) {
  return commaSeparatedFormatter.format(Number(value)).replace(/,/g, '’');
}

export function trimToFixed(num: number, decimalPlaces: number) {
  const fixed = Math.pow(10, decimalPlaces);
  return Math.floor(num * fixed) / fixed;
}

// Shorts a number to a more readable format
// 1000 -> 1k
// 1000000 -> 1m
// 1000000000 -> 1b
// 1400 -> 1.4k
export function shortNumber(num: number) {
  let result = String(num);
  if (num >= 1000000000) {
    result = `${trimToFixed(num / 1000000000, 1)}b`;
  } else if (num >= 1000000) {
    result = `${trimToFixed(num / 1000000, 1)}m`;
  } else if (num >= 1000) {
    result = `${trimToFixed(num / 1000, 1)}k`;
  } else if (num >= 10) {
    result = `${trimToFixed(num, 1)}`;
  } else if (num >= 1) {
    result = `${trimToFixed(num, 2)}`;
  }

  return result;
}

// return fraction part without integer part and zeros
export function fractionPart(num: number, decimalPlaces = 3) {
  const fraction = num - Math.floor(num);
  return fraction.toString().slice(2, decimalPlaces + 2);
}
