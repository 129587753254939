import {css} from '@emotion/react';
import {MoonValue, useAppTheme} from '~/components/kit';
import {Chip} from '~/components/kit/chip';
import {Typography} from '~/components/kit/typography';
import {rem} from '~/shared/utils/style-helpers';
import cosmigBgSvg from './assets/cosmic-bg.svg';
import {
  getBlackHoleRank,
  getNextBlackHoleRank,
  getPreviousBlackHoleRank,
  isLastBlackHoleRank,
} from '../black-hole-ranks';
import {formatWithCommas} from '~/shared/utils/format/format-numbers';
import {lunar} from '~/entities/lunar-loot';
import {BlackHoleRankChip} from './black-hole-rank-chip';

export function BlackHoleProgressBar({amount}: {amount: number}) {
  const theme = useAppTheme();
  const currentBlackHoleRank = getBlackHoleRank(amount);
  const nextBlackHoleRank = getNextBlackHoleRank(amount);

  const currentStepSize = nextBlackHoleRank.size - currentBlackHoleRank.size;
  const currentStepThrown = Math.max(amount - currentBlackHoleRank.size, 0);

  let rankFullness = currentStepThrown / currentStepSize;

  if (isLastBlackHoleRank(amount)) {
    rankFullness = 1;
  }

  return (
    <div>
      <div
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: rem(8),
        }}
      >
        <Typography.body
          css={{
            color: theme.colors.onSurface,
            fontWeight: 400,
          }}
        >
          Singularity Size
        </Typography.body>

        <BlackHoleRankChip rank={currentBlackHoleRank} />
      </div>
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: ${rem(2)} ${rem(2)};
          border: 2px solid ${theme.colors.onSurface};
          border-radius: ${rem(10)};
          background: ${theme.colors.surface};
          color: ${theme.colors.onSurface};
          font-size: ${rem(theme.fontSize.s6)};
          box-shadow: 0px 2px 0px 0px #111320;
        `}
      >
        <div
          css={{
            borderRadius: 99999,
            width: `${rankFullness * 100}%`,
            background: theme.colors.shades.dark.surface,
            height: rem(8),
            marginLeft: rem(2),
            backgroundImage: `url(${cosmigBgSvg})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: '100%',

            transition: 'all 0.2s ease',
          }}
        ></div>
        <div
          css={{
            borderRadius: 99999,
            marginLeft: rem(2),
            marginRight: rem(2),
            width: `${(1 - rankFullness) * 100}%`,
            background: '#FF5050',
            height: rem(8),
            transition: 'all 0.2s ease',
          }}
        ></div>
      </div>
      <div
        css={{
          display: 'flex',
          justifyContent: nextBlackHoleRank ? 'space-between' : 'space-around',
          marginTop: rem(10),
        }}
      >
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <Typography.h8>Already Thrown In</Typography.h8>
          <div>
            <MoonValue
              value={formatWithCommas(lunar(amount))}
              css={{
                fontWeight: 400,
                fontSize: theme.fontSize.odds.s10,
                marginTop: rem(4),
              }}
            />
          </div>
        </div>
        {nextBlackHoleRank && (
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <Typography.h8>Next Singularity Lvl</Typography.h8>
            <div>
              <MoonValue
                value={formatWithCommas(lunar(nextBlackHoleRank.size))}
                css={{
                  fontWeight: 400,
                  fontSize: theme.fontSize.odds.s10,
                  marginTop: rem(4),
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
