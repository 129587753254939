import {titleCollapsed} from '../typography';
import moonSvg from './moon.svg';

interface MoonValueProps extends React.HTMLProps<HTMLSpanElement> {
  value?: number | string;
  moonSize?: string;
  splitNumbers?: boolean;
}

function splitBySymbol(value: string) {
  return (
    <div
      css={{
        display: 'inline',
      }}
    >
      {value.split('').map((char, index) => {
        if (char === '.' || char === ',') {
          return (
            <span
              key={index}
              css={{
                display: 'inline-block',
              }}
            >
              {char}
            </span>
          );
        }

        return (
          <span
            key={index}
            css={{
              display: 'inline-block',
              textAlign: 'center',
              width: index === 0 ? 'auto' : '1em',
              marginLeft: index === 0 ? '0' : '-0.2em',
              marginRight:
                index === value.length - 1 || index === 0 ? '0' : '-0.2em',
            }}
          >
            {char}
          </span>
        );
      })}
    </div>
  );
}

export function MoonValue({
  value,
  moonSize = '1em',
  splitNumbers,
  ...rest
}: MoonValueProps) {
  if (!value) {
    return (
      <span {...rest}>
        <img
          src={moonSvg}
          alt="moon"
          css={{
            width: moonSize,
            height: moonSize,
            verticalAlign: 'top',
          }}
        />
      </span>
    );
  }
  return (
    <span
      {...rest}
      css={[
        titleCollapsed,
        {
          display: 'inline-flex',
          alignItems: 'center',
          fontVariantNumeric: 'oldstyle-nums tabular-nums slashed-zero',
          fontFeatureSettings: `'titl' on, 'case' on`,
          verticalAlign: 'top',
        },
      ]}
    >
      <span
        css={{
          lineHeight: 1,
        }}
      >
        {splitNumbers ? splitBySymbol(value.toString()) : value}
      </span>
      <img
        src={moonSvg}
        alt="moon"
        css={{
          width: moonSize,
          height: moonSize,
          marginLeft: '0.1em',
          verticalAlign: 'top',
        }}
      />
    </span>
  );
}
